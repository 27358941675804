/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import {
  ShortText,
  Input,
  Button,
  TextEditor,
  Select,
  CreatableSelect,
} from 'components/common';
import api from 'api';
import {
  convertEditorToString,
  datePayloadParser,
  companyFormValidator,
  convertStringToEditor,
  sanitizeFileName,
  uploadFileToS3,
} from 'helpers';
import { companySizeOptions } from 'constants';

import * as styles from '../EditCompanyForm.styles';

const FirstStep = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [jobFunctionOptions, setJobFunctionOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCitiesOptions] = useState([]);
  const [rolePreferenceOptions, setRolePreferenceOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState({
    companyLogo: '',
    companyBanner: '',
  });
  const [formData, setFormData] = useState({});

  const handleParseRoles = (arr) => {
    if (arr.length) {
      const res = arr.map(({ id, label }) => ({ id, label, value: id }));
      return res;
    }
    return [];
  };

  const fetchCompanyProfile = async () => {
    const { data } = await api.getCompanyDetail(companyId);
    setFormData({
      name: data.data.name,
      description: convertStringToEditor(data.data.description),
      industry: data.data.industry?.id || '',
      size: data.data.company_size?.id,
      country: data.data?.country?.id || '',
      region: data.data?.province?.id || '',
      city: data.data?.city?.id || '',
      address: data.data.address,
      website: data.data.website,
      techStack: data.data.technology_stack,
      neededRoles: handleParseRoles(data.data.needed_roles) || [],
      type: data.data.type,
      agreementLink: data.data.ndas.find((d) => d.disclosing_company === 'hacktiv')?.link || '',
      kmiAgreementLink: data.data.ndas.find((d) => d.disclosing_company === 'kmi')?.link || '',
      agreementDate: data.data.ndas.find((d) => d.disclosing_company === 'hacktiv')
        ?.valid_from?.split('T')[0] || '',
      companyTrialStartDate: data.data.trial_start?.split('T')[0],
      companyTrialEndDate: data.data.trial_end?.split('T')[0],
      memberName: '',
      memberEmail: data.data.email,
      memberPhoneNumber: data.data.contact_number,
      memberStatus: '',
    });
    setImagePreview({
      companyLogo: data.data.logo_url,
      companyBanner: data.data.banner_url,
    });
  };

  const fetchAllJobFunctions = async () => {
    const { data } = await api.getAllIndustries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setJobFunctionOptions(modifiedArr);
  };

  const fetchRolePreferences = async () => {
    const { data } = await api.getAllRolePreferences();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      id,
      value: id,
    }));
    setRolePreferenceOptions(modifiedArr);
  };

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${formData.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${formData.region || 1}&country_id=${formData.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCitiesOptions(modifiedArr);
  };

  const handleFileChange = (e, name) => {
    if (e.target.files.length) {
      setImagePreview((prevState) => ({
        ...prevState,
        [name]: URL.createObjectURL(e.target.files[0]),
      }));
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files[0],
      }));
    }
  };

  const handleChangeTextEditor = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      description: e,
    }));
  };

  useEffect(() => {
    if (companyId) {
      fetchCompanyProfile();
    }
    fetchAllJobFunctions();
    fetchRolePreferences();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [formData.country, formData.region]);

  const handleFormChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleMultiSelect = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      neededRoles: d,
    }));
  };

  const neededRolesParser = (arr) => arr.map((d) => d.id);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        name: formData.name,
        type: formData.type,
        website: formData.website,
        industry: formData.industry,
        company_size: formData.size,
        address: formData.address,
        description: convertEditorToString(formData.description),
        companyLogo: formData.companyLogo || '',
        companyBanner: formData.companyBanner || '',
        logo_url: formData.logo_url,
        banner_url: formData.banner_url,
        agreement_link: formData.agreementLink,
        city_id: +formData.city,
        technology_stack: formData.techStack,
        needed_roles: neededRolesParser(formData.neededRoles),
        trial_start: datePayloadParser(formData.companyTrialStartDate),
        trial_end: datePayloadParser(formData.companyTrialEndDate),
        hacktiv_nda_link: formData.agreementLink,
        hacktiv_nda_valid_from: datePayloadParser(formData.agreementDate),
        hacktiv_nda_valid_until: null,
        kmi_nda_link: formData.kmiAgreementLink,
      };
      let response = '';
      response = companyFormValidator(payload);
      if (response === 'valid') {
        Object.keys(payload).forEach((key) => {
          if (!payload[key] && payload[key] !== '') {
            delete payload[key];
          }
        });
        if (payload.companyLogo) {
          const resp = await uploadFileToS3(
            new File([payload.companyLogo], sanitizeFileName(payload.companyLogo.name)),
            'companyLogo',
          );
          delete payload.companyLogo;
          payload.logo_url = resp;
        }
        if (payload.companyBanner) {
          const resp = await uploadFileToS3(
            new File([payload.companyBanner], sanitizeFileName(payload.companyBanner.name)),
            'companyBanner',
          );
          delete payload.companyBanner;
          payload.banner_url = resp;
        }
        const { data } = await api.editCompany(companyId, payload);
        if (data.code === 200) {
          setNotification(true);
          setNotificationContent({
            type: 'success',
            message: data.message,
          });
          navigate('/companies');
        }
      } else {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} css={styles.rightContainerStyles}>
      <ShortText styles={styles.titleStyles}>edit company</ShortText>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company name*</ShortText>
          <Input
            name="name"
            value={formData.name}
            type="text"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company name"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company description</ShortText>
          <TextEditor
            options={['inline', 'list', 'textAlign', 'link']}
            editorValue={formData.description}
            handleChangeEditor={handleChangeTextEditor}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company industry*</ShortText>
          <Select
            name="industry"
            value={formData.industry}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="select company industry"
            iconCustomStyles={styles.selectIconStyles}
            options={jobFunctionOptions}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>organization size*</ShortText>
          <Select
            name="size"
            value={formData.size}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="select organization size"
            iconCustomStyles={styles.selectIconStyles}
            options={companySizeOptions}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>country*</ShortText>
          <Select
            name="country"
            value={formData.country || 1}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="negara"
            iconCustomStyles={styles.selectIconStyles}
            options={countryOptions}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>region/state/province*</ShortText>
          <Select
            name="region"
            value={formData.region}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="provinsi"
            iconCustomStyles={styles.selectIconStyles}
            options={regionOptions}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>city*</ShortText>
          <Select
            name="city"
            value={formData.city}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="kota"
            iconCustomStyles={styles.selectIconStyles}
            options={cityOptions}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>office address</ShortText>
          <Input
            className="address"
            type="text"
            name="address"
            value={formData.address}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company address"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link website</ShortText>
          <Input
            type="text"
            name="website"
            value={formData.website}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company website"
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>technology stack*</ShortText>
          <Input
            type="text"
            name="techStack"
            value={formData.techStack}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company techStack"
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>position search*</ShortText>
          <CreatableSelect
            containerStyles={styles.customSelectContainerStyles}
            options={rolePreferenceOptions}
            value={formData.neededRoles}
            onChange={handleMultiSelect}
            placeholder="position search"
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company status*</ShortText>
          <Select
            name="type"
            value={formData.type}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="select status"
            iconCustomStyles={styles.selectIconStyles}
            options={[
              {
                label: 'non hiring partner',
                value: 'non-hiring-partner',
              },
              {
                label: 'hiring partner',
                value: 'hiring-partner',
              },
            ]}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link agreement</ShortText>
          <Input
            type="text"
            value={formData.agreementLink}
            name="agreementLink"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company agreement link"
            onChange={handleFormChange}
            disabled
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link agreement KMI</ShortText>
          <Input
            type="text"
            value={formData.kmiAgreementLink}
            name="kmiAgreementLink"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company agreement link"
            onChange={handleFormChange}
            disabled
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link agreement date</ShortText>
          <Input
            type="date"
            value={formData.agreementDate}
            name="agreementDate"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company agreement date"
            onChange={handleFormChange}
            disabled
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>Company Trial Start Date</ShortText>
          <Input
            type="date"
            value={formData.companyTrialStartDate}
            name="companyTrialStartDate"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>Company Trial end Date</ShortText>
          <Input
            type="date"
            value={formData.companyTrialEndDate}
            name="companyTrialEndDate"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company logo (optional)</ShortText>
          <Button styles={styles.uploadButtonLogoStyles}>
            {imagePreview.companyLogo && (
              <img css={styles.companyLogoStyles} src={imagePreview.companyLogo} alt="logo" />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, 'companyLogo')}
            />
            <ShortText>
              Max. 2Mb (90x90px) in JPEG or PNG format.
            </ShortText>
          </Button>
        </div>
        <div css={styles.twoThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company banner (optional)</ShortText>
          <Button className="banner" styles={styles.uploadButtonLogoStyles}>
            {imagePreview.companyBanner && (
              <img css={styles.companyBannerStyles} src={imagePreview.companyBanner} alt="logo" />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, 'companyBanner')}
            />
            <ShortText>
              Max. 2Mb (900x300px) in JPEG or PNG format.
            </ShortText>
          </Button>
        </div>
      </div>
      <div css={styles.buttonContainerStyles}>
        <Button
          type="submit"
        >
          update company profile
        </Button>
      </div>
    </form>
  );
};

export default FirstStep;
