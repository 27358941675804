/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
} from 'components/common';
import api from 'api';
import confirmationImage from 'assets/companies/confirmation.png';

import * as styles from './ActivateMemberModal.styles';

const ActivateMemberModal = ({
  isActivateMemberModalOpen,
  setActivateMemberModal,
  fetchCompanyUsers,
  selectedMember,
}) => {
  const { companyId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);

  const handleChangeStatus = async () => {
    const payload = {
      name: selectedMember.name,
      email: selectedMember.email,
      role: selectedMember.role,
      status: 'active',
    };
    try {
      const { data } = await api.updateCompanyUser(companyId, selectedMember.id, payload);
      if (data.code === 200) {
        await fetchCompanyUsers(companyId);
        setNotification(true);
        setNotificationContent({ type: 'success', message: data.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.message });
    } finally {
      setActivateMemberModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isActivateMemberModalOpen}
      setModal={setActivateMemberModal}
    >
      <div css={styles.containerStyles}>
        <img css={styles.imageStyles} src={confirmationImage} alt="confirmation" />
        <ShortText styles={styles.titleStyles}>activate account</ShortText>
        <ShortText styles={styles.descriptionStyles}>
          are you sure to activate your account member?
        </ShortText>
        <div css={styles.buttonContainerStyles}>
          <Button className="cancel" type="button" styles={styles.buttonStyles}>
            cancel
          </Button>
          <Button type="button" onClick={handleChangeStatus} styles={styles.buttonStyles}>
            yes, continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ActivateMemberModal.propTypes = {
  isActivateMemberModalOpen: PropTypes.bool.isRequired,
  setActivateMemberModal: PropTypes.func.isRequired,
  fetchCompanyUsers: PropTypes.func.isRequired,
  selectedMember: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default ActivateMemberModal;
