/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import {
  Button,
  ShortText,
  Icon,
  NavLink,
} from 'components/common';
import companyLogo from 'assets/placeholder/company-logo-placeholder.jpeg';
import dateParser from 'helpers/dateParser/jobsCardDeadlineParser';

import * as styles from './CompanyCard.styles';

const CompanyCard = ({
  data, handleOpenAgreementModal, handleOpenChangeStatusModal, handleOpenDeleteModal,
}) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.leftCardStyles}>
      <div css={styles.imageContainerStyles}>
        <img src={data.logo_url || companyLogo} alt="company-logo" />
      </div>
      <div css={styles.contentContainerStyles}>
        <div css={styles.partnerContainerStyles}>
          <ShortText styles={styles.partnerPillStyles(data.type)}>
            {data.type}
          </ShortText>
          {
            data.last_login && (
              <ShortText styles={styles.loggedinTextStyles}>
                {`Last logged in ${dateParser(data.last_login)}`}
              </ShortText>
            )
          }
        </div>
        <NavLink
          path={`/companies/${data.id}/company-detail`}
          styles={styles.companyTextStyles}
        >
          {data.name}
        </NavLink>
        <div css={styles.bottomContainerStyles}>
          <div css={styles.bottomRowContainerStyles}>
            {
              data.website && (
                <div css={styles.iconValueStyles}>
                  <Icon name="globe" />
                  <ShortText className="lowercase">
                    {data.website}
                  </ShortText>
                </div>
              )
            }
            {/* <div css={styles.iconValueStyles}>
              <Icon name="user" />
              <ShortText>
                1022 applicants
              </ShortText>
            </div> */}
          </div>
          <div css={styles.bottomRowContainerStyles}>
            <div css={styles.iconValueStyles}>
              <Icon name="building" />
              <ShortText>
                {data.industry}
              </ShortText>
            </div>
            {/* <div css={styles.iconValueStyles}>
              <Icon name="briefcase" />
              <ShortText>
                5 active jobs
              </ShortText>
            </div> */}
          </div>
          <div css={styles.bottomRowContainerStyles}>
            {
              data.province?.label && (
                <div css={styles.iconValueStyles}>
                  <Icon name="map-marker-alt" />
                  <ShortText>
                    {`${data.province.label}, ${data.country.label}`}
                  </ShortText>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
    <div css={styles.rightContainerStyles}>
      <div css={styles.buttonContainerStyles}>
        <NavLink
          path={`/companies/${data.id}/edit-company`}
        >
          edit
        </NavLink>
        <Button
          type="button"
          onClick={() => handleOpenAgreementModal(data)}
          disabled={!data.can_send_nda}
        >
          {!data.can_send_nda ? 'agreement signed' : 'send agreement'}
        </Button>
      </div>
      <div css={styles.toggleContainerStyles}>
        <Button type="button" onClick={() => handleOpenChangeStatusModal(data.id, data.status)}>
          <Icon name={data.status === 'inactive' ? 'toggle-off' : 'toggle-on'} />
        </Button>
        <Button type="button" onClick={() => handleOpenDeleteModal(data.id)}>
          <Icon name="trash" />
        </Button>
      </div>
    </div>
  </PlainCard>
);

CompanyCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    last_login: PropTypes.string,
    type: PropTypes.string,
    logo_url: PropTypes.string,
    can_send_nda: PropTypes.bool,
    website: PropTypes.string,
    industry: PropTypes.string,
    status: PropTypes.string,
    province: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  }).isRequired,
  handleOpenAgreementModal: PropTypes.func.isRequired,
  handleOpenChangeStatusModal: PropTypes.func.isRequired,
  handleOpenDeleteModal: PropTypes.func.isRequired,
};

export default CompanyCard;
