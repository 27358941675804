/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
} from 'components/common';
import api from 'api';

import * as styles from './DeactivateMemberModal.styles';

const DeactivateMemberModal = ({
  isDeactivateMemberModalOpen,
  setDeactivateMemberModal,
  fetchCompanyUsers,
  selectedMember,
}) => {
  const { companyId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [action, setAction] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name: selectedMember.name,
      email: selectedMember.email,
      role: selectedMember.role,
      status: selectedMember.status === 'active' || selectedMember.status === 'pending'
        ? 'inactive' : 'active',
    };
    try {
      let data = null;
      if (action === 'deactivate') {
        data = await api.updateCompanyUser(companyId, selectedMember.id, payload);
      } else {
        data = await api.deleteCompanyUser(companyId, selectedMember.id);
      }
      if (data.data.code === 200) {
        await fetchCompanyUsers(companyId);
        setNotification(true);
        setNotificationContent({ type: 'success', message: data.data.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.message });
    } finally {
      setDeactivateMemberModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isDeactivateMemberModalOpen}
      setModal={setDeactivateMemberModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>manage member</ShortText>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div className="no-margin" css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>edited</ShortText>
            <ShortText styles={styles.emailStyles}>{selectedMember.email}</ShortText>
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <input type="radio" name="option" value="deactivate" onChange={(e) => setAction(e.target.value)} />
          <div>
            <ShortText styles={styles.labelStyles}>deactivate</ShortText>
            <ShortText styles={styles.textStyles}>
              The account will no longer to process job,
              but still available in your teams,
              You can set your team to fully function by activated members
            </ShortText>
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <input type="radio" name="option" value="delete" onChange={(e) => setAction(e.target.value)} />
          <div>
            <ShortText styles={styles.labelStyles}>delete</ShortText>
            <ShortText styles={styles.textStyles}>
              The account will no longer available,
              and all the data in the account will be permanently deleted.
            </ShortText>
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button className="cancel" onClick={() => setDeactivateMemberModal(false)} type="button" styles={styles.buttonStyles}>
            cancel
          </Button>
          <Button type="submit" styles={styles.buttonStyles}>
            confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

DeactivateMemberModal.propTypes = {
  isDeactivateMemberModalOpen: PropTypes.bool.isRequired,
  setDeactivateMemberModal: PropTypes.func.isRequired,
  fetchCompanyUsers: PropTypes.func.isRequired,
  selectedMember: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default DeactivateMemberModal;
